<template>
  <div class="row">

    <div class="col-md-12">
      <div class="div_front">
        <span v-if="user.my_debug_interface" class="pull-right span_white">
          <a :href="getDebugApiEntry(apiCallFinal)" target="_blank">
            &nbsp;&nbsp;<i class="fa fa-gear"></i> OPEN API</a>
        </span>
      </div>

      <div v-if="news_articles.length > 0">
        <vnud-card class="card card-article" v-for="news in news_articles " :key="news" inverted
          :badge-type="getBadgeClassType(news.sentiment_score)" :badge-icon="getBadgeNowIcon(news.sentiment_score)"
          :noFooterLine="true">

          <template v-slot:header>
            <span class="pull-right image-padding" v-html="getImage(news)"></span>
            <span class="pull-right" v-if="news.experiment"><small>{{ news.experiment }}</small></span>

            <span :class="getBadgeClass(news.sentiment_score) + ' news_link'" @click="openLink(news)">
              <span class="news_link" v-html="getTitle(news)">
              </span>
            </span>

            <div><br></div>

            <span v-if="news.tools">
              <h5 class="title news_link" v-html="getNoBullshit(news)" @click="openLinkComments(news)">
              </h5>
            </span>

            <p v-html="markdown(news.ai_summary)">
            </p>

            <p v-if="!news.ai_summary">
              {{ news.articles[0] }}
            </p>

            <!-- LEGACY... Inteligencia Artificial instead of Artificial Intelligence, spanish brainfart -->
            <p v-if="news.ia_summary && !news.ai_summary">
              <span v-html="markdown(news.ia_summary)">
              </span>
            </p>

          </template>

          <template v-slot:footer>
            <div class="row">
              <div class="col-md-12">

                <div class="pull-right" v-for="tickers in news.related_exchange_tickers " :key="tickers">
                  <div v-if="this.$route.query.symbol != tickers"  class="local-margin-left">
                    <n-button @click="gotoTicker(tickers)"
                      type="outline-primary" size="sm"><small>{{ tickers }}</small></n-button>
                  </div>
                </div>

                <div class='top_padding div_timeago'>
                  <small><b class="dark_color ">{{ timeAgo(news.creation_date) }}</b></small>
                  <small v-if="news.source">&nbsp; <i> by {{ news.source }}</i></small>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <hr>
                <div class="action-items col-md-12">
                  <span class="action_item" :class="{ 'selected-class': currentCommentArticle === news.id }">
                    <a href="javascript:void(0)" @click="openCommentEditor(news)">
                      {{ news.no_comments }} Comments
                    </a>
                  </span>
                  <span class="action_item" :class="{ 'selected-class': currentShareArticle === news.id }">
                    <a href="javascript:void(0)" @click="openShareEditor(news.id)">
                      Share
                    </a>
                  </span>
                  <span class="action_item" :class="{ 'selected-class': currentReportArticle === news.id }">
                    <a href="javascript:void(0)" @click="openReportEditor(news.id)">
                      Report
                    </a>
                  </span>
                  <span class="" v-if="user.is_admin">
                    <CheckboxSmall :inline="true" v-model="news.is_blocked" @change="handleBlock(news)">Hide
                    </CheckboxSmall>
                  </span>
                </div>

                <span v-if="user.my_debug_interface" class="pull-right">

                  <a :href="getDebugApiEntry('/news/force_reindex/' + news.id)" target="_blank">
                    &nbsp;&nbsp;<i class="fa fa-refresh"></i> AI Reindex </a>

                  <a :href="getDebugApiEntry('/news/get/' + news.id)" target="_blank">
                    &nbsp;&nbsp;<i class="fa fa-gear"></i> Open API </a>

                </span>
              </div>
            </div>
            <SharePanel :news_id="news.id" :news_title="getTitleSharing(news)" v-if="currentShareArticle === news.id" />

            <div class="comment_editor" v-if="currentCommentArticle === news.id || currentReportArticle === news.id">
              <div v-if="currentReportArticle === news.id">
                <i class="fa fa-bug"></i> Specify what is the problem about this post.
              </div>

              <QuillEditor :options="options" v-if="logged_in"
                :toolbar="['bold', 'italic', 'strike', 'link', { 'list': 'ordered' }, { 'list': 'bullet' }]"
                @textChange="handleTextChange" v-model:content="newCommentContent" contentType="html" theme="snow" />

              <div v-if="!logged_in">
                <h6>
                  <router-link class="link footer-link" to="/register?comment=article">
                    <i class='fa fa-link'></i> Please click here to register and comment...
                  </router-link>
                </h6>
              </div>

              <n-button v-if="newCommentContent != ''" @click="submitComment(news.id)" type="default"
                size="sm">Submit</n-button>

              <n-button v-if="newCommentContent != ''" @click="cancelComment()" type="outline-default"
                size="sm">Cancel</n-button>

              <small v-if="user.my_debug_interface" class='pull-right'>{{ newCommentContent }}</small>

              <news-comments :article="news" v-if="currentCommentArticle === news.id">
              </news-comments>

            </div>
          </template>
        </vnud-card>
      </div>

      <div class="pagination-controls" v-if="isPagination">
        <n-button @click="loadPreviousPage" :disabled="currentPage === 1">Back</n-button>
        <n-button @click="loadNextPage" class="pull-right" :disabled="!hasMoreData">Next</n-button>
      </div>
      <div v-else>
        <i v-if="!isLoaded" class="fa fa-2x fa-spinner fa-spin"></i>
      </div>

    </div>
  </div>
</template>
<script>
import { TimeLine, MainItem, TimeLineItem, SharePanel } from "@/components";

import NewsComments from "@/views/dashboard/comments/NewsComments.vue";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import globalConfig from "@/globalConfig.js";
import globalController from "@/globalController.js";

import utils from "@/globalUtil.js";

import { mapState, mapActions } from "vuex";
import {
  CheckboxSmall,
} from "@/components";

export default {
  components: {
    TimeLine,
    TimeLineItem,
    SharePanel,
    MainItem,
    QuillEditor,
    CheckboxSmall,
    NewsComments,
  },
  props: {
    isPagination: {
      type: Boolean,
      default: false,
    },
    symbol: String,
    apicall: String,
  },
  created() {
    this.loadData();
    if (!this.isPagination)
      window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    if (!this.isPagination)
      window.removeEventListener('scroll', this.handleScroll); // Clean up the event listener
  },

  data() {
    return {
      newCommentContent: "",
      options: {
        // TODO Add markdown instead of HTML https://github.com/aral/quill-markdown-shortcuts-for-vue-quill-editor

        // debug: 'info',
        toolbar: 'minimal',
        placeholder: 'Place a comment and remember to be human...',
        theme: 'snow',
      },
      currentPage: 0,
      hasMoreData: true,
      pageSize: 10,    // Number of items per page
      ticker: "",
      isLoaded: false,
      currentCommentArticle: null,
      currentShareArticle: null,
      currentReportArticle: null,
      news_articles: [],
      apiCallFinal: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  methods: {
    handleBlock(article) {
      console.log(" HANDLE BLOCK " + article.is_blocked);
      globalController.api_set_property("/news", article.id, "is_blocked", article.is_blocked,
        (data) => {
        },
        (error) => {
          console.log(" ERROR " + error);
          utils.runToast(` ${error} `, "now-ui-icons ui-1_bell-53", "bottom-right", "danger");
        });
    },
    openViewComments(article) {
      article.comments_opened = !article.comments_opened;
    },
    timeAgo(datetime) { return utils.timeAgo(datetime) },
    getTitleSharing(article) {
      let title = this.getNoBullshit(article);
      if (!title)
        title = this.getTitle(article);
      // Remove HTML tags;
      return title.replace(/<[^>]*>/g, '');
    },
    handleTextChange(delta, oldDelta, source) {
      console.log("Text changed:", delta);
      console.log("Old content:", oldDelta);
      console.log("Source of change:", source);
    },
    getDebugApiEntry(apiCall) {
      return globalConfig.getApiPath(apiCall);
    },
    resetActions() {
      this.currentCommentArticle = null;
      this.currentShareArticle = null;
      this.currentReportArticle = null;
    },
    cancelComment() {
      this.resetActions();
    },
    openShareEditor(articleId) {
      this.resetActions();
      this.currentShareArticle = articleId;
      console.log(" SHARE OPEN ");
    },
    openReportEditor(articleId) {
      if (!this.logged_in) {
        this.$router.push("/register?source=report");
        return;
      }

      this.resetActions();
      this.currentReportArticle = articleId;
      console.log(" REPORT OPEN ");
    },
    openCommentEditor(article) {
      // Set the current article ID to show the editor for that article only
      if (this.currentCommentArticle == article.id) {
        this.resetActions();
        return;
      }

      this.resetActions();
      this.newCommentContent = ''; // Reset comment content each time
      this.currentCommentArticle = article.id;
      this.openViewComments(article);
    },
    submitComment(articleId) {
      // Logic to post the comment content to the API or handle locally

      let my_comment = {
        'parent_obj': 'news',
        'parent_obj_id': articleId,
        'content': this.newCommentContent,
      };

      if (this.currentReportArticle === articleId) {
        my_comment['is_report'] = true;
      }

      console.log(`Submitting comment for article ${articleId}:`, this.newCommentContent);
      globalController.save_json_update(
        '/comments/create',
        my_comment,
        (data) => {

          if (data.status == "success") {
            utils.runToast(
              ` Saved `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "info"
            );

            this.currentCommentArticle = articleId;
            return
          }

          utils.runToast(
            ` Failed uploading comment ${data.error_msg} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        },
        (error) => {
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      )

      // Here, you would send `this.newCommentContent` to the API or handle it as needed
      // After submission, clear the editor
      this.currentCommentArticle = null;
      this.newCommentContent = '';
    },
    handleScroll() {
      // Only load when we are already loaded
      if (!this.isLoaded)
        return;

      const scrollY = window.scrollY || window.pageYOffset;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;

      // If the user is within 300px of the bottom of the page and more data is available, load next page    
      if (scrollY + visible >= pageHeight - 300 && this.hasMoreData) {
        this.currentPage++;
        this.loadData(); // Load the next page
      }
    },
    postNewComment() {
      this.isPostComment = true;
    },

    loadPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadData();
      }
    },

    // Logic to load the next page
    loadNextPage() {
      if (this.hasMoreData) {
        this.currentPage++;
        this.loadData();
      }
    },
    gotoTicker(ticker) {
      console.log(" TICKER " + ticker);
      this.$router.push("/company/view?symbol=" + ticker);
    },
    getSentimentScore(scores) {
      // Check if scores is a ProxyArray or regular array and has at least one element
      if (Array.isArray(scores) && scores.length > 0) {
        return scores[0];
      }
      return 0; // Default value if the array is empty or not valid
    },
    getScoreFromArray(score_array) {
      let score = 0;
      try {
        score = score_array[0];
      }
      catch (err) {
        console.log(err);
      }

      return score;
    },
    openLinkComments(article) {
      this.$router.push(globalController.get_news_link({ 'id': article.id, 'title': this.getNoBullshit(article) }))
    },
    openLink(article) {
      window.open(article.link);
    },
    cleanTitle(title) {
      // Split the title into an array of words
      const words = title.split(' ');

      // If the title has more than 10 words, slice the first 10 and append "..."
      if (words.length > 10) {
        return words.slice(0, 10).join(' ') + '...';
      }

      // If the title has 10 words or less, return it as is
      return title
    },
    getImage(article) {
      try {
        let gif_url = article.tools[0].function.arguments.gif_keywords;
        if (!gif_url)
          return;

        return `
          <video width="164" autoplay loop muted playsinline onerror="this.remove();">
            <source src="/api/news/gif?keywords=${gif_url}" type="video/mp4">
            Your browser does not support the video tag.
          </video>`;

        //return "<img width='164px' src='/api/news/gif?keywords=" + gif_url + "'  onerror='this.remove();'>"
      } catch (e) {
        console.log("");
      }

      return "";
    },
    getTitle(article) {
      try {
        let title = article.tools[0].function.arguments.title;
        if (title.length > 0)
          return this.cleanTitle(title);
      } catch (e) {
        //console.log(e);
      }

      return this.cleanTitle(article.title);
    },
    getNoBullshit(article) {
      try {
        if (article.tools[0] && article.tools[0].function.arguments.no_bullshit)
          return this.markdown(article.tools[0].function.arguments.no_bullshit);
      } catch (e) {
        //console.log(e);
        return article.title;
      }
    },
    getBadgeClassType(score_array) {
      if (!score_array)
        return 'success';

      let score = this.getScoreFromArray(score_array);
      if (score <= -5) {
        return 'danger';
      } else if (score > -5 && score < 0) {
        return 'warning';
      } else if (score === 0) {
        return 'info';
      } else if (score > 0 && score < 5) {
        return 'primary';
      }

      return 'info';
    },
    getBadgeClass(score_array) {
      return 'badge badge-' + this.getBadgeClassType(score_array);
    },
    getBadgeIcon(score_array) {
      if (!score_array)
        return 'business_briefcase-24';
      let score = this.getScoreFromArray(score_array);

      if (score == 0) return 'business_briefcase-24';

      if (score < -7) return 'sport_user-run';
      if (score < -5) return 'design_scissors';
      if (score < 0) return 'objects_umbrella-13';
      if (score > 1) return 'ui-2_like';
      if (score > 3) return 'sport_trophy';
      if (score > 5) return 'ui-2_favourite-28';
      if (score > 7) return 'objects_diamond';

      return 'business_briefcase-24';
    },
    getBadgeNowIcon(score_array) {
      return "now-ui-icons " + this.getBadgeIcon(score_array);
    },
    markdown(src) {
      if (!src)
        return "";

      return utils.markdown(src);
    },
    async loadData(company_name) {
      this.isLoaded = false;
      const queryParams = this.$route.query;

      let apiCall = "";

      const currentScrollPosition = window.scrollY || window.pageYOffset;

      // Does the component specify an API call?
      if (this.apicall) {
        apiCall = this.apicall;
      } else {
        if (queryParams.id) {
          apiCall = "/news/query?id=" + encodeURIComponent(queryParams.id);
        } else {
          apiCall = "/news/query?ai_summary__ne=NULL&order_by=-creation_date"; // &creation_date__gte=1%20day&
          let l = apiCall.length;

          if (queryParams.symbol)
            apiCall += "&related_exchange_tickers=" + encodeURIComponent(queryParams.symbol);
          else
            if (this.symbol)
              apiCall += "&related_exchange_tickers=" + encodeURIComponent(this.symbol);

          // We don't have anything to load
          if (apiCall.length == l)
            return;
        }
      }

      // Don't ignore experiments if we specifically say so.
      if (queryParams.experiment) {
        apiCall = apiCall.replace("experiment__ne=NULL", '');
        apiCall += "&experiment=" + queryParams.experiment;
      } else
        if (!apiCall.includes('experiment__'))
          apiCall += "&experiment=NULL";

      apiCall += `&skip=${this.currentPage * this.pageSize}&limit=${this.pageSize}`;

      let page = this.currentPage;

      this.apiCallFinal = apiCall;

      globalController.api_call(
        apiCall,
        (result) => {
          if (result.news.length == 0) {

            if (this.isPagination) {
              this.news_articles = [];
              this.isLoaded = true;
            } else
              this.hasMoreData = false;

          } else {

            if (this.isPagination)
              this.news_articles = result.news;
            else
              this.news_articles = [...this.news_articles, ...result.news];

          }

          this.isLoaded = true;

          console.log("News result: Result " + page);
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  }
};
</script>
<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.image-padding {
  padding-left: 10px;
}

.action-items {
  display: flex;
  gap: 16px;
  /* Controls spacing between each action item */
}

.action-item {
  padding-left: 10px;
  /* Adjust as needed for spacing */
}

.action-item a {
  text-decoration: none;
  color: #007bff;
  /* Customize color as needed */
  cursor: pointer;
}

.action-item a:hover {
  text-decoration: underline;
}

.selected-class a {
  color: #007bff !important;
}

.div_front {
  z-index: 9000;
}

.span_white a {
  color: #fff !important;
}

.dark_color {
  color: #555 !important;
}

.top_padding {
  padding-top: 15px;
}

.news_link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.comment_editor {
  padding-top: 5px;
}

.div_timeago {
  min-width: 200px !important;
  overflow: hidden;
}

.card-article {
  padding: 4px;
  margin-top: 20px;
}

.card {
  max-width: 100vw;
  overflow: clip;
  /* add scrolling if content overflows */
}

.news_link {
  max-width: 100vw;
  overflow: clip;
  /* add scrolling if content overflows */
}

.local-margin-left {
  margin-left: 5px;
}
</style>
