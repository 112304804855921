import * as Vue from 'vue'

if (process.env.NODE_ENV === 'development') {
    console.log(" NODE_ENV " + process.env.NODE_ENV);
}

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import store from "./globalStore.js";

import DashboardPlugin from "./dashboard-plugin";

const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Toast, options);

appInstance.use(DashboardPlugin);
appInstance.mount("#app");
