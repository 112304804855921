<template>
  <div class="col-md-4 ml-auto mr-auto">
    <vnud-card class="card-lock text-center" no-footer-line>
      <template v-slot:header>
        <div style='position:absolute; right:15px; cursor:pointer;'>
          <i class='fa fa-close fa-2x' @click="logout"></i>
        </div>
        <img :src="userImage" alt="..." />
      </template>

      <h4 class="card-title">{{ userFirstName }} <br> {{ userLastName }}</h4>
      <fg-input v-model="password" type="password" placeholder="Enter Password..">
      </fg-input>
      <template v-slot:footer>
      <div>
        <n-button type="primary" round wide @click="unlock">Unlock</n-button>
      </div>
      </template>

    </vnud-card>
  </div>
</template>

<script>
import globalConfig from "@/globalConfig.js";
import { mapState } from "vuex";
import utils from "@/globalUtil.js";

export default {
  name: "vnud-lock",
  computed: {
    ...mapState({
      userFirstName: (state) => state.user.first_name,
      userLastName: (state) => state.user.last_name,
    }),
  },
  data() {
    return {
      userImage: "/img/emilyz.jpg",
      userName: localStorage.getItem("userName"),
      password: "",
    };
  },
  setup() {    
    localStorage.removeItem("userToken");
  },
  methods: {
    logout() {
      console.log("LOCK: Remove user");
      localStorage.removeItem("userToken");
      localStorage.removeItem("userData");
      this.$router.push("/");
    },
    unlock() {
      console.log("Unlock button clicked");
      fetch(globalConfig.getApiPath("/user/login"), {
        method: "post",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: localStorage.getItem("userName"),
          password: this.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status != "success") {
            utils.runToast(
              ` ${data.error_msg} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
            return;
          }

          localStorage.setItem("userToken", data.token);
          localStorage.setItem("userName", data.current_user);

          this.$router.push("/dashboard");
        })
        .catch((error) => {
          utils.runToast(
              ` There was an error accesing this server `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
