<template>
  <div>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">

      <side-bar>
        <template v-slot:links>
          <user-menu v-if="logged_in"></user-menu>
          <sidebar-item v-if="logged_in" :link="{
            name: 'Dashboard',
            icon: 'now-ui-icons design_app',
            path: '/dashboard',
          }">
          </sidebar-item>

          <sidebar-item v-if="logged_in"
            :link="{ icon: 'now-ui-icons objects_spaceship', name: 'My_Portfolio_Feed', path: '/news/my_portfolio' }">
          </sidebar-item>

          <sidebar-item
            :link="{ icon: 'now-ui-icons business_briefcase-24', name: 'AI_Feed', path: '/news/latest_ai' }">
          </sidebar-item>
          <sidebar-item :link="{ icon: 'now-ui-icons sport_user-run', name: 'Bullish_News', path: '/news/bullish' }">
          </sidebar-item>
          <sidebar-item
            :link="{ icon: 'now-ui-icons objects_support-17', name: 'Bearish_News', path: '/news/bearish' }">
          </sidebar-item>
          <!--
          <sidebar-item v-if="logged_in" :link="{ name: 'My_Portfolio', icon: 'now-ui-icons objects_spaceship' }">
            <sidebar-item :link="{ name: 'Track', path: '/portfolio/register' }"></sidebar-item>
            <sidebar-item :link="{ name: 'View', path: '/company/view' }"></sidebar-item>
          </sidebar-item>
        -->
          <sidebar-item v-if="logged_in" :link="{ name: 'Company', icon: 'now-ui-icons business_briefcase-24' }">
            <sidebar-item :link="{ name: 'Explore', path: '/company/explore' }"></sidebar-item>
            <!--
            <sidebar-item :link="{ name: 'View', path: '/company/view' }"></sidebar-item>
            <sidebar-item :link="{ name: 'News', path: '/company/news' }"></sidebar-item>
          -->
          </sidebar-item>

          <sidebar-item v-if="user.my_debug_interface"
            :link="{ name: 'Debugging', icon: 'now-ui-icons design_palette' }">
            <sidebar-item :link="{ name: 'Experiments', path: '/news/experiments' }"></sidebar-item>
          </sidebar-item>

          <sidebar-item v-if="logged_in"
            :link="{ icon: 'now-ui-icons ui-1_zoom-bold', name: 'Watch', path: '/stock/register' }">
          </sidebar-item>

          <sidebar-item :link="{ icon: 'now-ui-icons sport_user-run', name: 'News_Feed', path: '/news/latest' }">
          </sidebar-item>

          <sidebar-item v-if="user.my_dev_interface" :link="{ name: 'Pages', icon: 'now-ui-icons design_image' }">
            <sidebar-item :link="{ name: 'Pricing', path: '/pricing' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Timeline', path: '/pages/timeline' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Login', path: '/login' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Register', path: '/register' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Lock Screen', path: '/lock' }"></sidebar-item>
            <sidebar-item :link="{ name: 'User Profile', path: '/pages/user' }"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="user.my_dev_interface"
            :link="{ name: 'Components', icon: 'now-ui-icons education_atom' }">
            <sidebar-item :link="{ name: 'Buttons', path: '/components/buttons' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Grid System', path: '/components/grid-system' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Panels', path: '/components/panels' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Sweet Alert', path: '/components/sweet-alert' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Notifications', path: '/components/notifications' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Icons', path: '/components/icons' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Typography', path: '/components/typography' }"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="user.my_dev_interface"
            :link="{ name: 'Forms', icon: 'now-ui-icons files_single-copy-04' }">
            <sidebar-item :link="{ name: 'Regular Forms', path: '/forms/regular' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Extended Forms', path: '/forms/extended' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Validation Forms', path: '/forms/validation' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Wizard', path: '/forms/wizard' }"></sidebar-item>
          </sidebar-item>

          <sidebar-item v-if="user.my_dev_interface"
            :link="{ name: 'Tables', icon: 'now-ui-icons design_bullet-list-67' }">
            <sidebar-item :link="{ name: 'Regular Tables', path: '/table-list/regular' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Extended Tables', path: '/table-list/extended' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Paginated Tables', path: '/table-list/paginated' }"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="user.my_dev_interface" :link="{ name: 'Maps', icon: 'now-ui-icons location_pin' }">
            <sidebar-item :link="{ name: 'Google Maps', path: '/maps/google' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Full Screen Maps', path: '/maps/full-screen' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Vector Maps', path: '/maps/vector-map' }"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="user.my_dev_interface" :link="{
            name: 'Widgets',
            icon: 'now-ui-icons objects_diamond',
            path: '/widgets',
          }"></sidebar-item>
          <sidebar-item v-if="user.my_dev_interface" :link="{
            name: 'Charts',
            icon: 'now-ui-icons business_chart-pie-36',
            path: '/charts',
          }"></sidebar-item>
          <sidebar-item v-if="user.my_dev_interface" :link="{
            name: 'Calendar',
            icon: 'now-ui-icons media-1_album',
            path: '/calendar',
          }"></sidebar-item>
        </template>
      </side-bar>
      <div class="main-panel">
        <div>
          <top-mainbar :isMain="false"></top-mainbar>
          <top-navbar></top-navbar>
        </div>

        <router-view name="header"></router-view>

        <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
          <router-view></router-view>
        </div>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

// function hasElement(className) {
//   return document.getElementsByClassName(className).length > 0;
// }
//
// function initScrollbar(className) {
//   if (hasElement(className)) {
//     new PerfectScrollbar(`.${className}`);
//   } else {
//     // try to init it later in case this component is loaded async
//     setTimeout(() => {
//       initScrollbar(className);
//     }, 100);
//   }
// }

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import UserMenu from "./extra/UserMenu.vue";

import TopMainbar from "./TopMainbar.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    TopMainbar,
    TopNavbar,
    ContentFooter,
    UserMenu,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    })
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss">
.blackcolor {
  background-color: black;
}
</style>
