<template>
  <div class="user user__menu">
    <div class="photo">
      <img :src="image" alt="avatar" />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        href="#userMenu"
        role="button"
        aria-controls="userMenu"
        aria-expanded="false"
      >
        <span>
          {{ userFirstName }} {{ userLastName }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" id="userMenu">
        <ul class="nav user-menu__nav">
          <slot>
            <li>
              <a href="#/pages/user">
                <span class="sidebar-mini-icon">UP</span>
                <span class="sidebar-normal">User Profile</span>
              </a>
            </li>
            <!--
            <li>
              <a href="#/pages/settings">
                <span class="sidebar-mini-icon">S</span>
                <span class="sidebar-normal">Settings</span>
              </a>
            </li>
            -->
          </slot>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "user-menu",
  computed: {
    ...mapState({
      userFirstName: (state) => state.user.first_name,
      userLastName: (state) => state.user.last_name,
    }),
  },
  props: {
    title: {
      type: String,
      default: "TITLE",
    },
    image: {
      type: String,
      default: "/img/emilyz.jpg",
    },
  },
};
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
</style>
