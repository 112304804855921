<template>
  <navbar id="nav_top">

    <div class="navbar-wrapper">
      <a class="navbar-brand" v-if="isMain" href="/">Tothemoon.life</a>
    </div>

    <template v-slot:navbar-menu>
      <router-link v-if="!isUserDefined" to="/register" v-slot="{ href, isActive, isExactActive }" custom>
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons tech_mobile"></i>
            Register
          </a>
        </li>
      </router-link>

      <li class="nav-item" :class="[
        isActive && 'active router-link-active',
        isExactActive && 'router-link-exact-active',
      ]">
        <a href="https://discord.gg/avC37y4Wby" class="nav-link">
          <i class="fab fa-lg fa-discord "></i>&nbsp;
          DISCORD
        </a>
      </li>

      <router-link to="/about" v-slot="{ href, isActive, isExactActive }" custom>
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons objects_planet"></i>
            ABOUT
          </a>
        </li>
      </router-link>

      <router-link v-if="isUserDefined & isMain" to="/login" v-slot="{ href, isActive, isExactActive }" custom>
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons users_circle-08"></i>
            Logout
          </a>
        </li>
      </router-link>

      <router-link to="/pricing" v-slot="{ href, isActive, isExactActive }" custom v-if="!user.subscription">
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons business_money-coins"></i>
            Pricing
          </a>
        </li>
      </router-link>

      <ul class="navbar-nav div_pages">
        <!--
        <li class="nav-item">
          <a class="nav-link" href="javascript:;">
            <i class="now-ui-icons media-2_sound-wave"></i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        -->
        <drop-down v-if="logged_in" tag="li" position="right" class="nav-item" icon="now-ui-icons location_world">
          <router-link class="dropdown-menu-test dropdown-item" to="/lock">
            Lock
          </router-link>
          <router-link class="dropdown-menu-test dropdown-item" to="/login">
            Logout
          </router-link>
        </drop-down>

        <li class="nav-item" v-if="isUserDefined">
          <router-link class="nav-link" to="/pages/prompt" v-if="logged_in">
            <small><i class="now-ui-icons objects_diamond" v-if="user.subscription"></i></small>
            <small><i class="fa fa-trophy" v-if="!user.subscription"></i></small>
            
            <p>
              <span class="d-lg-none d-md-block">AI</span>
            </p>
          </router-link>
        </li>
        <li class="nav-item" v-if="isUserDefined">
          <router-link class="nav-link" to="/pages/user" v-if="logged_in">
            <i class="now-ui-icons users_single-02"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </router-link>

          <router-link class="nav-link" to="/login" v-if="!logged_in">
            <i class="now-ui-icons users_single-02"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </router-link>
        </li>
      </ul>
      <!--
  <router-link
    v-if="isUserDefined"
    to="/lock"
    v-slot="{ href, isActive, isExactActive }"
    custom
  >
    <li
      class="nav-item"
      :class="[
        isActive && 'active router-link-active',
        isExactActive && 'router-link-exact-active',
      ]"
    >
      <a :href="href" class="nav-link">
        <i class="now-ui-icons ui-1_lock-circle-open"></i>
        Lock
      </a>
    </li>
  </router-link>
  -->
    </template>
    <menubar></menubar>
  </navbar>

</template>
<script>
import { Menubar, Navbar, NavbarToggleButton, AutoComplete } from "@/components";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Menubar,
    Navbar,
    NavbarToggleButton,
    AutoComplete,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isUserDefined() {
      return (localStorage.getItem("userToken") !== null);
    },
  },
  props: {
    isMain: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    setPageClass(to) {
      this.pageClass = `${to.name}-page`.toLowerCase();
    },
  },
};
</script>
<style>
#nav_top {
  z-index: 19000;
}

.div_pages {
  z-index: 2023 !important;
}

.dropdown-menu-test {
  z-index: 9900 !important;
}
</style>
