<template>
  <vnud-card class="card-user" v-if="logged_in">
    <template v-slot:header>
      <div v-if="user.subscription">
        <div class="pull-right"><small>Currently subscribed to: </small><br>
          <i class="now-ui-icons objects_diamond pull-right"></i>
          {{ user.subscription }}
        </div>
      </div>

      <h5 class="title">Edit Your AI Prompts</h5>

      <div>
        <p>Please provide a description or request for the AI to generate your personal news feed. </p>
        Try to be clear and specific so that the AI can better understand your needs.
        <!--
          Example:

          Instead of: "Tell me about dogs."
          Try: "Tell me about the different breeds of dogs and their characteristics."
          Instructions:
        -->

        Keep your prompt short and clear.
        Think about what you want the AI to do (e.g., answer a question, provide suggestions, generate ideas).
        If you're unsure, you can always ask for more specific help in our forums or discord channels.

      </div>

      <br>

      <QuillEditor :options="options" v-if="logged_in" :toolbar="['bold', 'italic', { 'list': 'bullet' }]"
        v-model:content="promptCompanyContent" contentType="html" theme="snow" />

      <n-button v-if="newCommentContent != ''" @click="submitEdit(promptAi)" type="default" size="sm">Test</n-button>

      <n-button v-if="newCommentContent != ''" @click="submitPrompt(promptAi)" type="success"
        size="sm">Submit</n-button>

      <n-button v-if="newCommentContent != ''" @click="cancelEdit()" type="outline-default" size="sm">Cancel</n-button>

    </template>
    <form v-if="user.subscription">
      <div class="row">
        <div class="col-md-12 ">
          <div>Example test here:</div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="square_test_box">
            ...
          </div>
        </div>
      </div>

    </form>
    <div v-if="!user.subscription">
      You don't have a subscription.
      <br>
      <router-link to="/pricing?from=subscription_management">
        <i class="now-ui-icons objects_diamond"></i>&nbsp;
        <small>You could buy a plan here!</small>
      </router-link>
    </div>

  </vnud-card>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { mapState } from "vuex";
import {
  Switch,
} from "@/components";

export default {
  name: "user-menu",
  components: {
    [Switch.name]: Switch,
    QuillEditor,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  data() {
    return {
      promptCompanyContent: "",
      promptPortoflioContent: "",
      options: {
        toolbar: 'minimal',
        placeholder: 'Edit...',
        theme: 'snow',
      },
    };
  },
  props: {
  },
};
</script>
<style scoped>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}

.card_body_limit {
  min-height: 100px;
}

.square_test_box {
  background-color: transparent;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  color: #2c2c2c;
  line-height: normal;
  height: auto;
  font-size: 0.8571em;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: none;
  
  padding-left: 15px;
  padding-right: 25px;
  margin-top: 5px;  
  margin-left: 5px;  
  margin-right: 15px;  
}
</style>
