<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <div class="row pull-right">
            <div class="col-sm-10 checkbox-radios">
              <small>
                <router-link to="/register?watch_stock=company" v-if="!logged_in">
                  Login to activate
                </router-link>
              </small>
              <checkbox :disabled="!watchlistLoaded" v-model="checkboxes.watching">Watch this stock</checkbox>
            </div>
          </div>

          <h4 class="card-title ticker">
            {{ exchange }} : {{ ticker }} <i v-if="!isLoaded" class="fa fa-gear fa-spin"></i>
          </h4>
        </template>
        <div>
          <div class="row" v-if="index <= 0">
            <label class="col-sm-2 col-form-label">
              <h5>
                <a v-if="info.website" :href="info.website" target="_blank">{{
                  info.company_name
                }}</a>
              </h5>
            </label>
            <div class="col-sm-10">
              <span class="form-text">{{ info.gics_sector }}</span>
              <span class="form-text"></span>
              <span class="form-text">{{ info.gics_sub_industry }}</span>
              <p />
              <span class="form-text" v-html="markdown(info.ai_summary)"></span>

              <!-- SPANISH BRAIN FART Inteligencia Artificial instead of AI -->
              <span class="form-text" v-if="info.ia_summary && !info.ai_summary">
                <span class="form-text" v-html="markdown(info.ia_summary)"></span>
              </span>

              <span class="form-text" v-if="!info.ai_summary && !info.ia_summary">
                {{ info.long_business_summary }}
              </span>
              <p />
              <a v-if="info.website" :href="info.website" target="_blank">Website</a>
            </div>
          </div>
        </div>
      </vnud-card>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import { Checkbox, Radio } from "@/components/index";
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import Timeline from "../pages/Vnud-Timeline.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Checkbox,
  },
  props: {
    symbol: String,
    index: Number,
  },
  created() {
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  data() {
    return {
      name: "loading...",
      isLoaded: false,
      ticker: "",
      exchange: "",
      watchlistLoaded: false,
      info: this.setDefaultTickerInfo(),

      default_watchlist: null,
      checkboxes: {
        watching: false,
      },
    };
  },
  watch: {
    "checkboxes.watching": {
      handler(newValue, oldValue) {
        if (!this.default_watchlist) {
          debugger;
          return;
        }

        let is_on_list = this.default_watchlist.exchange_tickers.includes(this.symbol);
        if (is_on_list == newValue) return;

        const action = newValue ? "append" : "remove";
        const apicall = `/ticker/user/watchlist/${action}/default/${this.exchange}:${this.ticker}`;

        globalController.api_call(
          apicall,
          (result) => {
            console.log("Ok");
          },
          (error) => {
            console.log("Failed adding to watchlist");
            utils.runToast(
              ` ${error} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
          }
        );
      },
    },
    symbol: {
      immediate: true, // Executes the watcher immediately on component mount
      handler(newValue, oldValue) {
        if (!newValue)
          return;

        this.name = "...loading...";
        this.setDefaultTickerInfo();
        this.loadData(newValue);
      },
    },
  },
  methods: {
    markdown(src) {
      if (!src)
        return "";

      return utils.markdown(src);
    },
    setSymbol(symbol) {
      if (!symbol.includes(":")) {
        this.exchange = "";
        this.ticker = symbol;
        return;
      }

      const parts = symbol.split(':');
      this.exchange = parts[0];
      this.ticker = parts[1];
    },

    setDefaultTickerInfo() {
      this.info = {
        company_name: "loading...",
        website: "",
      };
    },
    async invalidateSymbol(symbol) {
      globalController.api_call(
        "/ticker/invalidate/" + encodeURIComponent(symbol),
        (result) => {
          console.log("Invalidated symbol");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
        }
      );
    },
    async loadData(symbol) {
      this.isLoaded = false;
      this.watchlistLoaded = false;
      this.checkboxes.watching = false;
      if (!symbol)
        return;

      this.invalidateSymbol(symbol);
      globalController.api_call(
        "/company/query?exchange_tickers=" + encodeURIComponent(symbol),
        (result) => {
          this.isLoaded = true;
          this.setSymbol(symbol);

          if (result.companies && result.companies.length > 0) {
            this.info = result.companies[0];
            this.name = this.info.company_name;
            if (!this.info.website) {
              this.info.website = this.info.wikipedia;
            }
          }
          this.loadUserWatchlist("default");
          console.log("Company result");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    async loadUserWatchlist(listname) {
      globalController.api_call(
        "/ticker/user/watchlist/get/" + listname,
        (result) => {
          this.default_watchlist = result;
          this.watchlistLoaded = true;

          let extic = this.exchange + ":" + this.ticker;
          this.checkboxes.watching = result.exchange_tickers.includes(extic);
          console.log("User result");
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
        }
      );
    },
  },
};
</script>
<style></style>
