<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <div v-if="checkQuery('trial')">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto text-center">
          <h2 class="title text-white">Please register and login first!</h2>
          <h5 class="description">
            Each package includes Free Unlimited Updates and Premium Support.
          </h5>
        </div>
      </div>
    </div>

    <div v-if="checkQuery('comment') | checkQuery('watch_stock')">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto text-center">
          <h2 class="title text-white">Please register and login first!</h2>
          <h5 class="description">
            By registering you will be able to create your portfolio and comment!
          </h5>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-5 ml-auto"> <!-- Stock Tracking Section -->
        <div class="info-area info-horizontal mt-5">
          <div class="icon icon-primary">
            <i class="now-ui-icons media-2_sound-wave"></i>
          </div>
          <div class="description">
            <h5 class="info-title">Real-Time Stock Tracking</h5>
            <p>
              Get comprehensive insights and tracking for your stock portfolio.
            </p>
            <p class="description">
              Our platform offers real-time market data, advanced analytics, and expert recommendations to guide your
              trading decisions.
            </p>
          </div>
        </div>

        <!-- Registration Benefits -->
        <div class="info-area info-horizontal">
          <div class="icon icon-primary">
            <i class="now-ui-icons media-1_button-pause"></i>
          </div>
          <div class="description">
            <h5 class="info-title">Unlock Powerful Insights</h5>
            <p>Register now to access personalized stock insights and reports.</p>
            <p class="description">
              We deliver actionable insights and recommendations to maximize your portfolio's potential.
            </p>
          </div>
        </div>

        <!-- Customization Benefits -->
        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <i class="now-ui-icons users_single-02"></i>
          </div>
          <div class="description">
            <h5 class="info-title">Customized for Your Success</h5>
            <p class="description">
              Access a tailored dashboard that puts essential tools and analytics at your fingertips to achieve optimal
              returns.
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mr-auto">
        <vnud-card class="card-signup text-center" no-footer-line>
          <template v-slot:header>
            <h4 class="card-title">Register</h4>
            <!--
            <div class="social">
              <button class="btn btn-icon btn-round btn-twitter">
                <i class="fa fa-twitter"></i>
              </button>
              <button class="btn btn-icon btn-round btn-dribbble">
                <i class="fa fa-dribbble"></i>
              </button>
              <button class="btn btn-icon btn-round btn-facebook">
                <i class="fa fa-facebook"> </i>
              </button>
              <h5 class="card-description">or be classical</h5>
            </div>
          -->
          </template>

          <vee-input name="email" type="text" placeholder="Email..." addon-left-icon="now-ui-icons ui-1_email-85" />

          <vee-input name="username" type="text" placeholder="Username" addon-left-icon="now-ui-icons objects_diamond" />

          <vee-input name="firstName" type="text" placeholder="First Name..."
            addon-left-icon="now-ui-icons users_circle-08" />
          <vee-input name="lastName" type="text" placeholder="Last Name..."
            addon-left-icon="now-ui-icons text_caps-small" />

          <vee-input name="password" type="password" placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open" />

          <checkbox class="text-left" v-model="agree">
            I agree to the <a href="#something">terms and conditions</a>.
          </checkbox>

          <template v-slot:footer>
            <n-button type="primary" native-type="submit" round size="lg">
              Get Started
            </n-button>

            <hr>
            <router-link to="/login?from=register">
              <small>Already have an account?</small>
            </router-link>
          </template>
        </vnud-card>
      </div>
    </div>
  </Form>
</template>
<script>
import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";
import { ref, getCurrentInstance } from "vue";


import { Checkbox, VeeInput } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
export default {
  name: "vnud-register",
  components: {
    Checkbox,
    VeeInput,
    Form,
  },
  data() {
    return {
      agree: false,
    };
  },
  methods: {
    checkQuery(my_string) {
      let check = this.$route.query[my_string] != null;
      console.log(" Is trial " + check);
      return check;
    },
  },
  setup() {
    const { proxy } = getCurrentInstance();

    function onSubmit(values) {
      fetch(globalConfig.getApiPath("/user/create"), {
        method: "post",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
          username: values.username,
          first_name: values.firstName,
          last_name: values.lastName,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          debugger;
          if (data.status != "success") {
            utils.runToast(
              ` ${data.error_msg} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
            return;
          }

          localStorage.setItem("userToken", data.token);
          if (data.current_user)
            localStorage.setItem("userName", data.current_user);

          proxy.$store.dispatch("fetchUser");
          proxy.$router.push("/dashboard");
        })
        .catch((error) => {
          debugger;
          console.log(error);
          utils.runToast(
            ` There was a problem accessing this server, please try again later `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        });
    }

    const schema = Yup.object().shape({
      firstName: Yup.string().required().label("The First Name"),
      lastName: Yup.string().required().label("The Last Name"),
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(6).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  },
};
</script>
<style></style>
