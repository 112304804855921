<template>
  <vnud-card class="card-user" v-if="logged_in">
    <template v-slot:header>
      <div v-if="user.subscription">
        <div class="pull-right"><small>Currently subscribed to: </small><br>
          <i class="now-ui-icons objects_diamond pull-right"></i>
          {{ user.subscription }}
        </div>
      </div>

      <h5 class="title">Manage your subscription</h5>

    </template>
    <form v-if="user.subscription">
      <div class="row">
        <div class="col-md-6">
          <div>Your subscription is {{ user.subscription_status }}</div>
          <!--
          <fg-input label="Pause or cancel subscription here">
            <br>
            <n-switch v-model="user.my_pause_subscription" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_pause_subscription', user.my_pause_subscription)"></n-switch>
          </fg-input>
          -->
          <a href="/api/payment/portal"> Manage your subscription </a>
        </div>
      </div>
    </form>
    <div v-if="!user.subscription">
      You don't have a subscription.            
      <br>
      <router-link to="/pricing?from=subscription_management">        
        <i class="now-ui-icons objects_diamond"></i>&nbsp;
        <small>You could buy a plan here!</small>
      </router-link>
    </div>

  </vnud-card>
</template>
<script>
import { mapState } from "vuex";
import {
  Switch,
} from "@/components";

export default {
  name: "user-menu",
  components: {
    [Switch.name]: Switch,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  props: {
    image: {
      type: String,
      default: "/img/emilyz.jpg",
    },
  },
};
</script>
<style scoped>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}

.card_body_limit {
  min-height: 100px;
}
</style>
