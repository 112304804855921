<template>
  <navbar class="navbar-dashboard" :show-navbar="showNavbar" id="navigation">
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
        <navbar-toggle-button @click="toggleSidebar"> </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="javascript:;">
        {{ $route.name }}
      </a>
    </div>
    <button @click="toggleNavbar" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
      aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <template v-slot:navbar-menu>
      <form v-on:submit.prevent="perform_search">
        <div class="input-group no-border search-min-size">
          <fg-input v-model="query" placeholder="Search..." addon-right-icon="now-ui-icons ui-1_zoom-bold">
          </fg-input>
          <AutoComplete apicall="/company/suggestions" :suggestions="items" v-model:query="query"
            @update:suggestionSelected="perform_search" @suggestionsUpdated="nothing" />
        </div>
      </form>

    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton, AutoComplete } from "@/components";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    AutoComplete,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      query: "",
      items: [],
    };
  },
  methods: {
    perform_search() {
      console.log(" SUBMIT " + this.query);
      this.$router.push("/company/view?symbol=" + this.query);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    nothing() { },
  },
};
</script>
<style>
.search-min-size {
  width: 350px;
  min-width: 350px;
}

.navbar-dashboard {
  top: 50px;
}
</style>
