<template>
  <div>

    <top-mainbar></top-mainbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page-main section-image" :class="pageClass" filter-color="black" data-image="/img/bg13.jpg">
        <div class="content">
          <div class="container">
            <router-view></router-view>
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <nav>
              <ul>
                <li>
                  <a href="http://engineer.blue"> About Us </a>
                </li>
                <li>
                  <a href="http://engineer.blue"> Blog </a>
                </li>
              </ul>
            </nav>
            <div class="copyright">
              &copy; {{ year }},
              <a href="https://engineer.blue" target="_blank" rel="noopener">eBlue</a>
            </div>
          </div>
        </footer>

        <div class="full-page-background" style="background-image: url('/img/bg13.jpg')"></div>
      </div>
    </div>
  </div>
</template>
<script>
import TopMainbar from "./TopMainbar.vue";
import { Menubar, Navbar, NavbarToggleButton } from "@/components";

export default {
  computed: {},
  components: {
    Navbar,
    TopMainbar,
    Menubar,
    NavbarToggleButton,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  mounted() {
    console.log("AUTH: Mount auth layout ");
    this.checkUser();
  },

  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: `${this.$route.name}-page`.toLowerCase(),
      isUserDefined: localStorage.getItem("userToken") !== null,
    };
  },
  methods: {
    checkUser() {
      this.isUserDefined = localStorage.getItem("userToken") !== null;
      console.log("Check user " + this.isUserDefined + " userToken " + localStorage.getItem("userToken"));
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setPageClass(to) {
      this.pageClass = `${to.name}-page`.toLowerCase();
    },
  },
  beforeUnmount() {
    console.log("AUTH: Unmount auth layout ");
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    console.log("AUTH: Before router update");
    this.checkUser();
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route(to) {
      this.setPageClass(to);
    },
  },
};
</script>
<style lang="scss"></style>

<style  lang="scss" scoped>
.full-page-main {

    &>.content,
    &>.footer {
        position: relative;
        z-index: 4;
    }

    &.section-image {
        position: initial;
    }

    &>.content {
        padding-bottom: 150px;
        padding-top: 65px;
    }

    .footer {
        position: absolute;
        width: 100%;
        bottom: 0;

        .container {
            color: #FFF;
        }
    }

    .full-page-background {
        position: fixed;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
    }

    &:after {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0, 0, 0, 0.6);
    }

    &.pricing-page {
        .description {
            margin-bottom: 65px;
        }
    }

    &.register-page {
        .info-horizontal {
            padding: 0px 0px 20px;
        }

        .info-horizontal {
            text-align: left !important;

            .icon {
                margin-top: 0;

                >i {
                    font-size: 2em;
                }

                &.icon-circle {
                    width: 65px;
                    height: 65px;
                    max-width: 65px;
                    margin-top: 8px;

                    i {
                        display: table;
                        margin: 0 auto;
                        line-height: 3.5;
                        font-size: 1.9em;
                    }
                }
            }

            .description {
                overflow: hidden;
            }

        }
    }
}
</style>
