<template>
  <div class="row">
    <div class="col-lg-12">
      <AI-prompt-edit> </AI-prompt-edit>
    </div>

  </div>
</template>
<script>
import AIPromptEdit from "./AIPromptEdit.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AIPromptEdit,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
};
</script>
<style></style>
