<template>
  <vnud-card class="card-user">
    <template v-slot:image>
      <div class="image">
        <img src="/img/bg5.jpg" alt="..." />
      </div>
    </template>
    <div>
      <div class="author">
        <a href="#">
          <img class="avatar border-gray" src="/img/emilyz.jpg" alt="..." />
          <h5 class="title">
            {{ user.first_name }} <br />
            {{ user.last_name }}
          </h5>
        </a>
        <p class="description">{{ username }}</p>
      </div>
      <p class="description text-center">
        {{ user.about_me }}
      </p>
    </div>
    <template v-slot:footer_disabled>      
      <div class="button-container">
        <n-button href="#" type="neutral" icon round size="lg">
          <i class="fa fa-facebook-square"></i>
        </n-button>
        <n-button href="#" type="neutral" icon round size="lg">
          <i class="fa fa-twitter"></i>
        </n-button>
        <n-button href="#" type="neutral" icon round size="lg">
          <i class="fa fa-google-plus-square"></i>
        </n-button>
      </div>    
    </template>
  </vnud-card>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "user-menu",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  props: {
    image: {
      type: String,
      default: "/img/emilyz.jpg",
    },
  },
};
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
</style>
