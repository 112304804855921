<template>
  <nav class="menu-bar">
    <ul class="navbar-nav-small">
      <router-link to="/home" v-slot="{ href, isActive, isExactActive }" custom>
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons business_briefcase-24"></i>
            HOME
          </a>
        </li>
      </router-link>
      
      <router-link v-if="!logged_in"  to="/news" v-slot="{ href, isActive, isExactActive }" custom>
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons files_single-copy-04"></i>
            NEWS
          </a>
        </li>
      </router-link>

      <router-link v-if="logged_in"  to="/dashboard" v-slot="{ href, isActive, isExactActive }" custom>
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons users_circle-08"></i>
            DASHBOARD
          </a>
        </li>
      </router-link>

      <router-link to="/search" v-slot="{ href, isActive, isExactActive }" custom>
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons files_single-copy-04"></i>
            SEARCH
          </a>
        </li>
      </router-link>

      <router-link v-if="!logged_in" to="/login" v-slot="{ href, isActive, isExactActive }" custom>
        <li class="nav-item" :class="[
          isActive && 'active router-link-active',
          isExactActive && 'router-link-exact-active',
        ]">
          <a :href="href" class="nav-link">
            <i class="now-ui-icons users_circle-08"></i>
            LOGIN
          </a>
        </li>
      </router-link>

      <!--
        <router-link to="/news" v-slot="{ href, isActive, isExactActive }" custom>
          <li
            class="nav-item"
            :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a :href="href" class="nav-link">
              <i class="now-ui-icons ui-1_email-85"></i>
              CONTACT
            </a>
          </li>
        </router-link>
        -->

    </ul>
  </nav>
  <hr />

</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'vnud-menubar',

  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
    isUserDefined() {
      return (localStorage.getItem("userToken") !== null);
    },
  }
}
</script>

<style>
.menu-bar {

  padding: 10px;
}

.menu-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu-bar li {
  margin-right: 0px;
}

.menu-bar a {
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s;
}

.menu-bar a:hover {
  background-color: #555;
  border-radius: 5px;
}

.navbar-nav-small {
  font-size: x-small;
}
</style>
